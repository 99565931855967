import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>
  <b>Let's Chat! </b>
  <br />
  <br />
  I am Kuangyi Xing / 邢旷怡 (they/them/ta), based in Utrecht, the Netherlands. <br />
  <br />
  Feel free to reach out in English or Chinese (中文) – I'm fluent in both.
  <br />
  <br />
  E-mail: <a href>xingkuangyi@gmail.com</a>
  <br />
  Telephone: <a href>+31658831726</a>
  <br />
  KvK: <a href>90075668</a>
  <br />
  <br />
  or find me on other platforms:
  <br /> <a href="https://github.com/void-kuangyi">Github</a>
  <br />
  <a href="https://www.linkedin.com/in/kuangyi-xing/">Linkedin</a>
  <br />
  <a href="https://www.instagram.com/xingkuangyi/">Instagram</a>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      